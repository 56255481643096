import axios from 'axios';
import qs from 'qs';
import HMM from '~/web-base/components/HandmadeMessage';
import sEvent from '../../../utils/sensorsEvent';
import { getCmsLocaleByLangCode } from '../../../utils/locale';
import { get } from 'lodash';

// Create a centralized configuration for axios instance
const axiosInstance = axios.create({
  baseURL: process.env.CMS_APIURL && process.env.CMS_APIURL !== '/' ? process.env.CMS_APIURL : undefined,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${process.env.CMS_TOKEN}`
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    config.startTime = Date.now(); // Record request start time
    return config;
  },
  error => handleRequestError(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  response => handleResponse(response),
  error => handleResponseError(error)
);

// Error handling for requests
function handleRequestError(error) {
  const i18n = require('../../../config/request.i18n.conf')();
  HMM({ type: 'error', message: i18n.timeout });
  return Promise.resolve(error);
}

// Response handling
function handleResponse(response) {
  const duration = Date.now() - response.config.startTime;
  sEvent('ajax_response_time', response.request.responseURL, { duration });
  return response.status === 200 ? response.data : response;
}

// Error handling for responses
function handleResponseError(error) {
  const i18n = require('../../../config/request.i18n.conf')();
  const eventValue = error?.response?.request?.responseURL || error?.config?.url;
  const errInfo = error?.response ? JSON.stringify(error.response?.data) : error.code;
  sEvent('ajax_error', eventValue, { ext: errInfo });

  if (error.response) {
    handleHttpResponseError(error.response, i18n);
  } else if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
    HMM({ type: 'error', message: i18n.timeout });
  }

  return Promise.reject(error);
}

// Handle specific HTTP errors
function handleHttpResponseError(response, i18n) {
  const status = response.status;
  const msg = response?.data?.msg;
  if(status == 404){
    return
  }
  switch (status) {
    case 504:
      HMM({ type: 'error', message: i18n.notfound });
      break;
    case 403:
      HMM({ type: 'error', message: i18n.forbidden });
      break;
    case 401:
     /*  HMM({ type: 'error', message: i18n.expired });
      window.$nuxt.$logout(window.$nuxt.context).then(() => {
        window.location.href = `/${i18n.locale}login`;
      }); */
      break;
    case 500:
      HMM({ type: 'error', message: i18n.unknow });
      break;
    default:
      HMM({ type: 'error', message: msg || i18n.unknow });
      break;
  }
}

// Factory function to create API request objects
function createApiRequest(populateFields = '', locale) {
  // Build query based on locale and populate fields
  let query = {
    locale: getCmsLocaleByLangCode(locale),
  };

  // Append populate fields to the query if provided
  if (populateFields) {
    query.populate = populateFields;
  }
  // Return axios post and get methods
  return {
    post: (url, data) => axiosInstance.post(url, { ...data, ...query }),
    get: (url, data) => {
      return axiosInstance.get(url, {
        params: { ...data, ...query },
        paramsSerializer: (params) => qs.stringify(params),
      })
    },
    // get: (url, data) => axiosInstance.get(url, { params: { ...data, ...query } }),
  };
}


// API request instances
const cmsApiRequest = locale => createApiRequest(['thumbnail', 'seo'], locale);
const cmsApiNewsRequest = locale => createApiRequest('thumbnail', locale);
const cmsApiArticleRequest = locale => createApiRequest('thumbnail', locale);
const cmsApiPageRequest = locale => createApiRequest('seo.metaImage', locale);

export { cmsApiRequest, cmsApiNewsRequest, cmsApiArticleRequest, cmsApiPageRequest };
