import Vue from 'vue';
import { delLoginCookie, delCookie, logoutZendesk } from '~/utils/utils';
import { invokeClearCookie } from '~/server/user/index2';
import { broadcastChannel } from '~/utils/broadcastChannel'
import TokenCookie, {TokenKey} from "@/utils/TokenCookie";

Vue.prototype.$logout = ctx => {
  const response = new Promise((resolve, reject) => {
    invokeClearCookie()
      .then(res => {
        // console.log('00001 res', res)
        window.$nuxt.$store.commit('CLEAR_USER');
        window.$nuxt.$store.commit('SET_TRADER_NEW_TIPS', false);
        window.$nuxt.$store.commit('SET_MIX_TRADER_NEW_TIPS', false);
        window.$nuxt.$store.commit('future/UPDATE_LOGIN_STATUS', false);
        // 退出登录时候清楚合约显示白名单数据
        window.$nuxt.$store.commit('newTrading/SET_CONTRACT_ENABLE_DISPLAY', {});
        delLoginCookie();
        broadcastChannel('removeItem', 'bt_uid');
        TokenCookie.remove(TokenKey.CC_TOKEN)
        TokenCookie.remove(TokenKey.R_TOKEN)
        broadcastChannel('removeItem', 'lastLoginTime');
        TokenCookie.remove(TokenKey.CC_TOKEN_EXPIRE)
        TokenCookie.remove(TokenKey.REFRESH_TOKEN)
        TokenCookie.remove(TokenKey.REFRESH_TOKEN_EXPIRE)
        delCookie('bt_rtoken');
        delCookie('bt_newsessionid');
        delCookie('bt_sessonid');
        // logoutZendesk();
        resolve(res);
      })
      .catch(err => reject(err));
  });
  return response;
};


Vue.prototype.$clearServerAuth = ctx => {
  delLoginCookie();
  const response = new Promise((resolve, reject) => {
    invokeClearCookie()
      .then(res => {
        window.$nuxt.$store.commit('CLEAR_USER');
        window.$nuxt.$store.commit('SET_TRADER_NEW_TIPS', false);
        window.$nuxt.$store.commit('SET_MIX_TRADER_NEW_TIPS', false);
        window.$nuxt.$store.commit('future/UPDATE_LOGIN_STATUS', false);
        TokenCookie.remove(TokenKey.R_TOKEN)
        resolve(res);
      })
      .catch(err => reject(err));
  });
  return response;
};
