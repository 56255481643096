import Vue from 'vue';
import { broadcastChannel } from '~/utils/broadcastChannel';
import { isProduction } from '~/utils/envHelper.js';
Vue.mixin({
  methods: {
    // syncIdentityWhenMissing() {
    //   const rToken = getCookie('bt_rtoken') || localStorage.getItem('bt_rToken') || getCookie('bt_rtoken_client');
    //   const uid = sessionStorage.getItem('bt_uid') || localStorage.getItem('bt_uid');
    //   const accessToken = sessionStorage.getItem('bt_ccToken') || localStorage.getItem('bt_ccToken');
    //   if (rToken){
    //     if(rToken?.length > 0) {
    //       setCookie('bt_rtoken_client', rToken, {});
    //       broadcastChannel('setItem', 'bt_rToken', rToken);
    //     } else {
    //       broadcastChannel('removeItem', 'bt_rToken');
    //     }
    //   }
    //   if (uid) {
    //     if(uid?.length > 0) {
    //       localStorage.setItem('bt_uid', uid);
    //       broadcastChannel('setItem', 'bt_uid', uid);
    //     } else {
    //       broadcastChannel('removeItem','bt_uid');;
    //       broadcastChannel('removeItem', 'bt_uid');
    //     }
    //   }
    //   if (accessToken) {
    //     if(accessToken?.length > 0) {
    //       console.log('0000 accessToken-session', sessionStorage.getItem('bt_ccToken'))
    //       console.log('0000 accessToken-local', localStorage.getItem('bt_ccToken'))
    //       localStorage.setItem('bt_ccToken', accessToken);
    //       broadcastChannel('setItem', 'bt_ccToken', accessToken);
    //     } else {
    //       broadcastChannel('removeItem','bt_ccToken');;
    //       broadcastChannel('removeItem', 'bt_ccToken');
    //     }
    //   }
    // },
    /**
     * @param {String} val - 参数 ios || android
     * @param {String} type - 类型 download : 二维码 || open : 跳转下载页面
     * @returns {string} - 返回二维码下载链接
     */
    downloadJumpDl(type) {
      let locale = this.$i18n.locale; // 获取当前的语言环境
      let href = '';
      //const param = val ? '?' + val : ''; // 检查是否提供了类型参数
      href = location.origin + '/' + (locale === 'en' ? '' : locale + '/') + 'download';

      if (type == 'download') {
        return href;
      } else {
        window.open(href);
      }
    },
    go2AppStore() {
      let href =
        'https://apps.apple.com/app/weex-buy-bitcoin-crypto/id1609350789';
      window.open(href);
    },
    go2GooglePlay() {
      let href = 'https://play.google.com/store/apps/details?id=com.wake.weexprd';
      window.open(href);
    }
  },
  mounted() {
    // 启动token鉴权
    // this.$startAuthToken();
  }
});
