import { cmsApiNewsRequest, cmsApiArticleRequest, cmsApiPageRequest } from './fetch';
import languageMapping from '~/config/languageMapping';

class CMSApi {
  // Helper method to map language code based on locale
  getLangCode(locale) {
    return languageMapping?.find(item => item.language === locale)?.langCode || 0;
  }

  // Helper function to build query parameters
  buildCollectionParams(pagination = {}) {
    const { page, pageSize } = pagination;
    const currentDateTime = new Date().toISOString();
    return {
      enabled: true,
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
      filters: {
        $or: [
          { enabledDate: { $lte: currentDateTime } }, // Include data with valid enabledDate
          { enabledDate: { $null: true } }, // Include data with null enabledDate
          { expiredDate: { $gte: currentDateTime } }, // Include data with valid expiredDate
          { expiredDate: { $null: true } } // Include data with null expiredDate
        ]
      }
    };
  }

  // Generic API request handler
  async cmsApiCollectionRequest(endpoint, id = '', pagination = {}, locale, apiRequestFn) {
    try {
      const path = `/api/${endpoint}/${id || ''}`;  // Construct the path
      const langCode = this.getLangCode(locale);    // Get language code
      const params = this.buildCollectionParams(pagination);  // Build query params
      return apiRequestFn(langCode).get(path, params); // Execute request
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      throw error;
    }
  }

  // Generic API request handler
  async cmsApiSingleRequest(endpoint, locale, apiRequestFn) {
    try {
      const path = `/api/${endpoint}`;  // Construct the path
      const langCode = this.getLangCode(locale);    // Get language code
      return apiRequestFn(langCode).get(path); // Execute request
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      throw error;
    }
  }

  // Public method to fetch news
  async getNews(id = '', pagination = {}, locale) {
    return await this.cmsApiCollectionRequest('newss', id, pagination, locale, cmsApiNewsRequest);
  }

  // Public method to fetch articles
  async getArticles(id = '', pagination = {}, locale) {
    return await this.cmsApiCollectionRequest('articles', id, pagination, locale, cmsApiArticleRequest);
  }

  // Public method to fetch pages
  async getPages(pageName, locale) {
    return await this.cmsApiSingleRequest(pageName, locale, cmsApiPageRequest);
  }
}

// Export a singleton instance of CMSApi
const cmsApi = new CMSApi();
export default cmsApi;
