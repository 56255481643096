// 前端 统一 server 端服务封装

import { cacheApiFunc } from '~/utils/cacheApi/index';
import { getInstance } from '../utils/doRequest'
const commonInstance = getInstance('');

//切换语言
export const changeLanguage=(data)=>{
    var data=data||{};
     return  commonInstance.post("/v1/user/security/changeLanguage",data);
}

export const getLanguage = cacheApiFunc(() => {
  return commonInstance.get('/v1/user/security/getLanguageType')
})
