import { getFinger } from '~/utils/deviceInfo.js';
import { getLongUrl } from '../server/common';
import {isClient, isLocal} from '~/utils/envHelper';
import {TokenKey} from "../utils/TokenCookie";
import Vue from 'vue';
import {checkTradeDoamin, getBasicDomain, getTradeDomain} from "utils";
import {locales, TRADE_PAGE_PATH} from "~/config/global.config";
import { getLanguageKeyByLangCode } from '~/utils/locale';
import { matchFaRouteWithFaConfig } from '~/utils/matchRoute';
import { headerLocalActions } from '~/web-base/utils/localStorage/headerLocal';
import { languageKeyMap } from '~/config/languageMapping';

/**
 * 交易部分页面正则表达式
 * locales          - 所有的国际化locale Key
 * TRADE_PAGE_PATH  - 交易页面的路径白名单
 */
const TRADE_PAGE_REX = new RegExp(`^((${locales.map(locale => `\\/?${locale}`).join('|')})?\\/?(${TRADE_PAGE_PATH.join('|')}))`, 'i')

export default ({ app: { router, i18n }, store, redirect }) => {
  router.beforeEach((to, from, next) => {
    // WWIT-10243 Bug修复--清除LocalStorage的旧Token
    clearLocalStorageToken();

    /**
     * 匹配是否交易页面
     * 如果是交易页面，则location.href重定向跳转
     */

    if(TRADE_PAGE_REX.test(to.fullPath)){
      // 如果是本地开发环境则跳转到对应端口号
      if(isLocal){
        window.location.href = `${window.location.origin.replace(process.env.PORT, process.env.TRADE_PORT)}${to.fullPath}`;
        return;
      }
      window.location.href = `${window.location.origin}${to.fullPath}`;
      return;
    }

    let timerId = null;
    let { inviteCode, languageType } = to.query;
    // 从路径获取languageType，并判断如果路径不带/zh-CN，加上/zh-CN重定向
    if (languageType !== undefined) {
      const currentLang = store.state.locales.find(locale => {
        return to.fullPath.indexOf(`/${locale}`) > -1;
      });
      const locale = mapLanguageType(languageType);
      if (!currentLang && locale !== 'en') {
        // redirect('/' + local + to.fullPath);
        window.location.href = window.location.origin + '/' + locale + to.fullPath;
      }
    }

    // 如果邀请码存在并且与store中不一致，则获取长链信息
    if (inviteCode && inviteCode !== store.state.inviteCodeInfo[inviteCode]) {
      timerId = setTimeout(async () => {
        const res = await getLongUrl(inviteCode);
        if (res && res.data) {
          const data = JSON.parse(res.data);
          store.commit('SET_INVITE_CODE_INFO', { ...data, inviteCode });
          // 如果query中不存在languageType参数，则去长链中languageType
          // if (!languageType) {
          //   languageType = res.languageType;
          //   // 判断路径是否携带/en等语言
          //   const currentLang = store.state.locales.find(local => {
          //     return to.fullPath.indexOf(`/${local}`) > -1;
          //   });
          //   // 根据languageType获取语言,如果不是en则加到路径并跳转
          //   const local = mapLanguageType(languageType);
          //   if (!currentLang && local !== 'en') {
          //     // redirect('/' + local + to.fullPath);
          //     window.location.href =  window.location.origin + '/' + local + to.fullPath
          //   }
          // }
        }
      }, 500);
    }

    let terminalCode = isClient && localStorage.getItem('weex:terminalCode');
    if (!terminalCode) {
      getFinger(finger => {
        isClient && localStorage.setItem('weex:terminalCode', finger);
      });
    }
    // 如果路径/fa-IR，没有配置路转为 /
    const regFAIR = /^\/fa-IR/;
    const fullPath = to.fullPath;
    if (regFAIR.test(fullPath) && !matchFaRouteWithFaConfig(fullPath)) {
      timerId && clearTimeout(timerId);
      const currentLanguage = headerLocalActions.getCurrentLanguageLocal();
      let key = getLanguageKeyByLangCode(currentLanguage)
      if (key === languageKeyMap.en || key === languageKeyMap.fa) {
        key = ''
      } else {
        key = '/' + key
      }
      const newUrl = window.location.origin + to.fullPath.replace(/^\/fa-IR/, key);
      window.location.href = newUrl;
      return
    }

    // 如果路径是/en开始，重定向到不带/en
    const reg = /^\/en/;
    if (reg.test(to.fullPath)) {
      timerId && clearTimeout(timerId);
      next(to.fullPath.replace(/^\/en/, ''));
    } else {
      // 启动token鉴权
      if (!Vue.prototype.$authTokenInterval) {
        Vue.prototype.$startAuthToken();
      }
      next();
    }

  });

  // 根据languageType获取语言环境
  function mapLanguageType(languageType) {
    return getLanguageKeyByLangCode(languageType);
  }

  // 清除LocalStorage的就Token
  function clearLocalStorageToken() {
    if(isClient){
      Object.values(TokenKey).forEach(key => localStorage.removeItem(key) );
    }
  }
};
